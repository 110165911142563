import React, { useState, useEffect } from 'react';

// Components
import Panel from '../Panel/Panel';

// Moment
import moment from 'moment';

// Types
import { CarteirinhaImprimir } from "@core/@http/carteirinha/carteirinhaType";

// Styles
import styles from './Destaques.module.scss'

const tiposExames = [
  {value: 1, name: "Hemograma"},
  {value: 2, name: "Tipagem/RH"},
  {value: 3, name: "Coombs"},
  {value: 4, name: "Glicemia"},
  {value: 5, name: "TOTG"},
  {value: 6, name: "TSH/T4L"},
  {value: 7, name: "Súm. Urina"},
  {value: 8, name: "Urocultura"},
  {value: 9, name: "Sifilis"},
  {value: 10, name: "Toxoplasm."},
  {value: 11, name: "HIV"},
  {value: 12, name: "Hepatite B"},
  {value: 13, name: "Hepatite C"},
  {value: 14, name: "Cit. Onco."},
  {value: 15, name: "Strepto"},
  {value: 16, name: "Outros"},
];

const tiposExamesImagem = [
  { value: 1, name: "Morf. 1º tri" },
  { value: 2, name: "Morf. 2º tri" },
  { value: 3, name: "USG Obstétrico" },
  { value: 4, name: "Cervicometria" },
  { value: 5, name: "Doppler Obstétrico" },
  { value: 6, name: "USG Transvaginal" },
  { value: 7, name: "Eco. fetal" },
  { value: 8, name: "Outro" },
];

const tiposVacinas = [
  { value: 1, name: "Anti tetânica" },
  { value: 2, name: "Covid-19" },
  { value: 3, name: "Hepatite B" },
  { value: 4, name: "Influenza" },
];

const rows = Array.from({ length: 3 }).map(() => ({
  columns: Array.from({ length: 5 })
}))

interface iConsulta {
  key: string;
  idadeGestacional?: number;
  peso?: number;
  pressaoArterial?: number;
  dinamicaUterina?: number;
}

interface iExameLaboratorial {
  key: string;
  examesLaboratoriaisTipo?: number;
  nome?: string;
  date?: string;
  resultado?: string;
}

interface iExameImagem {
  key: string;
  examesDeImagemTipo?: number;
  date?: string;
  resultado?: string;
}

interface iVacina {
  key: string;
  vacinaTipos?: number;
  date?: string;
}

interface iDestaque {
  key: string;
  [key: string]: any
}

interface iProps {
  data?: CarteirinhaImprimir
}

const priorities = ["vacinasDestaque", "examesImagemDestaque", "consultasDestaque", "examesLaboratoriaisDestaque"];

const Destaques: React.FC<iProps> = ({ data }) => {
  const [destaques, setDestaques] = useState<iDestaque[]>();

  const populateDestaques = () => {
    let result: iDestaque[] = [];
    priorities.forEach((priority) => {
      if (data && data[priority]) {
        const addToDestaques = data[priority].map((item: any) => ({
          key: priority,
          ...item
        }))

        result = [...result, ...addToDestaques];
      }
    });

    setDestaques(result);
  }

  const getDestaque = (index: number) => destaques?.[index];

  const formatDate = (date?: string) => {
    if (!date) {
      return "dd/mm/aaaa";
    }

    return moment(date).format("DD/MM/YYYY");
  }

  const formatStringValue = (value?: string, truncate: number = 15) => {
    if (!value) {
      return null;
    }

    return value.substring(0, truncate);
  }

  const renderConsulta = (consulta: iConsulta) => (
    <div className={styles.consulta}>
      <span>
        IG: {consulta.idadeGestacional}
      </span>
      <span>
        Peso: {consulta.peso}
      </span>
      <span>
        PA: {consulta.pressaoArterial}
      </span>
      <span>
        Toque: {consulta.dinamicaUterina}
      </span>
    </div>
  )

  const renderExame = (name: string, data?: string, resultado: string = "-") => (
    <div className={styles.exame}>
      <span>
        {formatStringValue(name)}
      </span>
      <span>
        {formatDate(data)}
      </span>
      <span className={styles.resultado}>
        {formatStringValue(resultado, 45)}
      </span>
    </div>
  );

  const renderExameLaboratorial = (exame: iExameLaboratorial) => {
    const name = (exame.nome ? exame.nome : tiposExames.find((e) => e.value == exame.examesLaboratoriaisTipo)?.name) || "";

    return renderExame(name, exame.date, exame.resultado);
  }

  const renderExameImagem = (exame: iExameImagem) => {
    const name = (exame.examesDeImagemTipo ? tiposExamesImagem.find((e) => e.value == exame.examesDeImagemTipo)?.name : "") || "";

    return renderExame(name, exame.date, exame.resultado);
  }

  const renderVacina = (exame: iVacina) => {
    const name = (exame.vacinaTipos ? tiposVacinas.find((e) => e.value == exame.vacinaTipos)?.name : "") || "";

    return renderExame(name, exame.date, "OK");
  }

  const renderDestaque = (index: number) => {
    const destaque = getDestaque(index);

    if (!destaque) {
      return null;
    }

    switch (destaque.key) {
      case "consultasDestaque":
        return renderConsulta(destaque as iConsulta);
      case "examesLaboratoriaisDestaque":
        return renderExameLaboratorial(destaque as iExameLaboratorial);
      case "examesImagemDestaque":
        return renderExameImagem(destaque as iExameImagem);
      case "vacinasDestaque":
        return renderVacina(destaque as iVacina);
      default:
        return null;
    }
  }

  const renderDestaques = () => {
    let index = -1;

    return rows.map(({ columns }, iRow) => (
      <div key={iRow} className={styles.row}>
        {columns.map((_, iColumn) => {
          index++;

          return (
            <div key={iColumn} className={styles.destaque}>
              {renderDestaque(index)}
            </div>
          )
        })}
      </div>
    ))
  }

  useEffect(() => {
    console.log('data', data)
    populateDestaques();
  }, [data]);

  return (
    <Panel title='Destaques'>
      <div className={styles.container}>
        {renderDestaques()}
      </div>
    </Panel>
  )
}

export default Destaques;