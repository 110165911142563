import React, { useEffect, useState } from "react";

// MUI
import {
  Box,
  Stack,
  Skeleton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Endpoint
import exameLaboratoriais from "@core/@http/exameLaboratorias/exameLaboratorias";

// Moment
import moment from "moment";

// Types
import { ExameLaboratorial } from "@core/@http/exameLaboratorias/exameLaboratorias";
import { IPatientDetails } from "@core/models/patient";
import { ResponseCarteirinha } from "@core/@http/carteirinha/carteirinhaProblems";

// Components
import Button from "components/button/Button";
import Tab from "./components/tab/Tab";
import TabPanel from "./components/tabPanel/TabPanel";
import RotinaForm from "./components/rotinaForm/RotinaForm";
import OutrosForm from "./components/outrosForm/OutrosForm";

// Hooks
import { useToast } from "providers/ToastProvider/ToastProvider";

// Styles
import Prancheta from "../imagens/prancheta.png";
import styles from "../CardCartetinha.module.scss";
import { format } from "date-fns";

type consultaProps = {
  goBack: Function;
  patient: IPatientDetails | null | undefined;
  gestationId: string;
  historicoId: string;
  carteirinhaId: string;
  carteirinha: ResponseCarteirinha | undefined;
  isLoading: boolean;
};

const ExamesLabotoriaisScreen: React.FC<consultaProps> = ({
  goBack,
  gestationId,
  carteirinhaId,
  carteirinha,
  isLoading,
}) => {
  const [data, setData] = useState<ExameLaboratorial[]>();
  const [tab, setTab] = useState(1);

  const { toast } = useToast();
  const maxDateString = format(new Date(), "yyyy-MM-dd");

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const fetchData = async () => {
    const result = await exameLaboratoriais.getExameLaboratoriais(
      carteirinhaId
    );

    let indexOutros = -1;
    const resultData = result.map((exame) => {
      const exameData = {
        ...exame,
        gestationId,
        carteirinhaId,
        date: exame.date ? moment(exame.date).format("YYYY-MM-DD") : undefined,
      };

      if (exame.rotina == 4) {
        indexOutros += 1;

        return { ...exameData, index: indexOutros };
      }

      return exameData;
    });

    setData(resultData);
    setLoading(false);
  };

  const handleChangeTabs = (value: number) => setTab(value);

  const filterToModifyData = (
    value: ExameLaboratorial,
    compareTo: ExameLaboratorial
  ) =>
    value.id != compareTo.id ||
    value.rotina != compareTo.rotina ||
    value.examesLaboratoriaisTipo != compareTo.examesLaboratoriaisTipo ||
    value.index != compareTo.index;

  const handleOnChange = (
    dataModify: ExameLaboratorial | ExameLaboratorial[]
  ) => {
    if (Array.isArray(dataModify)) {
      let dataFilter = data || [];

      dataModify.forEach((compareTo) => {
        dataFilter = dataFilter?.filter((exame) =>
          filterToModifyData(exame, compareTo)
        );
      });

      setData([...dataFilter, ...dataModify]);
    } else {
      const dataFilter =
        data?.filter((exame) => filterToModifyData(exame, dataModify)) || [];
      setData([...dataFilter, dataModify]);
    }
  };

  const validateData = (dataToSave: ExameLaboratorial[]) => {
    dataToSave?.forEach((exame) => {
      if (
        !exame.date ||
        !exame.resultado ||
        (exame.rotina == 4 && !exame.nome)
      ) {
        throw Error(`Há campos obrigatórios não preenchidos, favor verificar.
            - Nas rotinas, data e resultado são obrigatórios;
            - Em outros, nome, data e resultado são obrigatórios;
            - Não pode haver exames destacados sem os demais dados preenchidos;
            - Não é obrigatório preencher todos os exames, porém os que forem preenchidos devem obedecer as regras acima.
          `);
      }
    });
  };

  const getDataToSave = () =>
    data?.filter((exame) => {
      // Rotinas preenchidas com algum valor
      // Nao considerar data, pois como é uma data unica para varios registro, pode haver registros somente com a data,
      // mas na tela nao esta preenchido resultado e nem destaque, logo este será desconsiderado ao salvar
      if (
        [1, 2, 3].includes(exame.rotina) &&
        (exame.resultado || exame.destaque)
      ) {
        return true;
      }

      // Outros preenchidos com algum valor
      if (
        [4].includes(exame.rotina) &&
        (exame.resultado || exame.nome || exame.destaque)
      ) {
        return true;
      }

      return false;
    });

  const enableSave = getDataToSave()?.length;

  const onClickSave = async () => {
    try {
      if (enableSave) {
        setSaving(true);

        const dataToSave = getDataToSave() as ExameLaboratorial[];

        validateData(dataToSave);

        const { createExameLaboratoria } = exameLaboratoriais;

        await createExameLaboratoria(dataToSave);
        setModalOpen(true);
        toast.success({
          title: "Sucesso",
          message: "Exames laboratoriais salvo com sucesso",
        });
      }
    } catch (error: any) {
      toast.error({
        title: "Erro",
        message: error.message,
      });
    } finally {
      setSaving(false);
    }
  };

  const onClickCancelar = () => {
    goBack();
  };

  useEffect(() => {
    fetchData();
  }, [carteirinhaId]);

  return (
    <div className={`${styles.card}`}>
      {loading || isLoading ? (
        <>
          <Skeleton height={150} />
          <Skeleton height={300} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
        </>
      ) : (
        <>
          <div className={styles.headerConsulta}>
            <div className={styles.containerBack}>
              <div className={styles.containerIcon}>
                <ChevronLeftIcon
                  onClick={() => goBack()}
                  style={{
                    color: "#1A535C",
                  }}
                />
              </div>
              <span className={styles.text}>Voltar</span>
            </div>
            <div className={styles.containerMid}>
              <img src={Prancheta} className={styles.imageContainer} />
              <div className={styles.containerText}>
                <span className={styles.title}>Exames Laboratoriais</span>
                <span className={styles.subTitle}>Preenchido</span>
                <span className={styles.desc}>
                  {carteirinha?.dataUltimaAtividadeExamesLaboratoriais}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.spacerHeader} />
          <div className={styles.line} />
          <div className={styles.spacer} />
          <Box sx={{ width: "100%", typography: "body1" }}>
            <Box display="flex" flexDirection="row">
              <Tab
                index={1}
                value={tab}
                label="Rotina 1"
                onChange={handleChangeTabs}
              />
              <Tab
                index={2}
                value={tab}
                label="Rotina 2"
                onChange={handleChangeTabs}
              />
              <Tab
                index={3}
                value={tab}
                label="Rotina 3"
                onChange={handleChangeTabs}
              />
              <Tab
                index={4}
                value={tab}
                label="Outros"
                onChange={handleChangeTabs}
              />
            </Box>
            <TabPanel index={1} value={tab}>
              <RotinaForm
                rotina={1}
                data={data}
                gestationId={gestationId}
                carteirinhaId={carteirinhaId}
                onChange={handleOnChange}
              />
            </TabPanel>
            <TabPanel index={2} value={tab}>
              <RotinaForm
                rotina={2}
                data={data}
                gestationId={gestationId}
                carteirinhaId={carteirinhaId}
                onChange={handleOnChange}
              />
            </TabPanel>
            <TabPanel index={3} value={tab}>
              <RotinaForm
                rotina={3}
                data={data}
                gestationId={gestationId}
                carteirinhaId={carteirinhaId}
                onChange={handleOnChange}
              />
            </TabPanel>
            <TabPanel index={4} value={tab}>
              <OutrosForm
                rotina={4}
                data={data}
                gestationId={gestationId}
                carteirinhaId={carteirinhaId}
                onChange={handleOnChange}
              />
            </TabPanel>
          </Box>

          <Stack direction="row" spacing={2} paddingTop="10px">
            <Button
              name="Salvar"
              isSubmit
              disabled={!enableSave}
              loading={saving}
              onClick={onClickSave}
              className={styles.btnAction}
            />

            <Button
              name="Cancelar"
              type="secondary"
              disabled={saving}
              onClick={onClickCancelar}
              className={`${styles.btnAction} ${styles.secondary}`}
            />
          </Stack>
          <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              <p style={{ paddingLeft: 95 }}>
                <strong>Rotina criada!</strong>
              </p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ justifyContent: "center" }}>
                Sua rotina foi criada e salva com sucesso.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <div
                style={{
                  backgroundColor: "#1A535C",
                  borderRadius: 50,
                  paddingLeft: 18,
                  paddingRight: 18,
                  width: 200,
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => setModalOpen(false)}
              >
                <p style={{ color: "#ffff", paddingLeft: 92 }}>
                  <strong>OK</strong>
                </p>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ExamesLabotoriaisScreen;
