import React from 'react';

// Components
import Panel from '../Panel/Panel';

// Types
import { CarteirinhaImprimir } from "@core/@http/carteirinha/carteirinhaType";

// Styles
import styles from './Carteirinha.module.scss'

interface iProps {
  data?: CarteirinhaImprimir
}

interface iLabelValue {
  label: string;
  value?: string | number | null
}

const LabelValue: React.FC<iLabelValue> = ({ label, value }) => (
  <>
    <div className={styles.label}>
      {label}
    </div>
    <div className={styles.value}>
      {value}
    </div>
  </>
)

const Carteirinha: React.FC<iProps> = ({ data }) => {
  return (
    <Panel title='Carteirinha'>
      <div className={styles.container}>
        <div className={styles.row}>
          <LabelValue label="Nome" value={data?.nomePaciente} />
          <LabelValue label="Risco" value={data?.riscoPaciente} />
        </div>
        <div className={styles.row}>
          <LabelValue label="Obstetra" value={data?.obstetraPaciente} />
          <LabelValue label="DPP DUM" value={data?.dppDumPaciente} />
        </div>
        <div className={styles.row}>
          <LabelValue label="Convênio" value={data?.convenioPaciente} />
          <LabelValue label="DPP Eco" value={data?.dppEcoPaciente} />
        </div>
      </div>
    </Panel>
  )
}

export default Carteirinha;