import React from "react";

// MUI
import { Box } from '@mui/material';

interface iProps {
  index: number;
  value: number;
  children?: any;
}

const TabPanel: React.FC<iProps> = ({ index, value, children }) => {
  if (index == value) {
    return (
      <Box paddingTop="10px">
        {children}
      </Box>
    )
  }

  return null;
};

export default TabPanel;