import React from 'react';

// MUI
import PublicIcon from "@mui/icons-material/Public";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";

// Styles
import styles from './Footer.module.scss';

// Imagens
import QrCode from "./imagens/qrcode.png";
import Logo from "./imagens/logo.png";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.qrcode}>
        <img src={QrCode} />
      </div>
      <div className={styles.logo}>
        <img src={Logo} />
      </div>
      <div className={styles.social}>
        <div className={styles.containerLine}>
          <PublicIcon className={styles.icon} />
          <span>www.obstcare.com</span>
        </div>
        <div className={styles.containerLine}>
          <InstagramIcon className={styles.icon} />
          <span>@obstcare</span>
        </div>
        <div className={styles.containerLine}>
          <EmailIcon className={styles.icon} />
          <span>contato@obstcare.com</span>
        </div>
      </div>
    </div>
  )
}

export default Footer;