import React from "react";

// MUI
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

// Moment
import moment from "moment";

// Components
import Panel from "../Panel/Panel";

// Types
import { Consulta } from "@core/@http/carteirinha/carteirinhaType";

// Styles
import styles from "./Consultas.module.scss";

interface iProps {
  data?: Consulta[];
}

const consultas = Array.from({ length: 10 });

const Consultas: React.FC<iProps> = ({ data }) => {
  const getValue = (
    prop: string,
    consulta?: Consulta,
    valueDefault: string = "-",
    truncate?: number
  ) => {
    if (!consulta) {
      return valueDefault;
    }

    const value = consulta[prop];

    if (value) {
      return String(value).substring(0, truncate);
    }

    return valueDefault;
  };

  const getValueDate = (
    prop: string,
    consulta?: Consulta,
    valueDefault: string = "-"
  ) => {
    const date = getValue(prop, consulta, valueDefault);

    if (date && date != valueDefault) {
      return moment(date).format("DD/MM/YYYY");
    }

    return valueDefault;
  };

  const getConsulta = (index: number) => data?.[index];

  return (
    <Panel title="Consultas">
      <TableContainer className={styles.table}>
        <Table size="small">
          {consultas.map((_, index) => {
            const consulta = getConsulta(index);
            if (!consulta || consulta.ocultar === true) {
              return null;
            }

            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="th">Cons. {index + 1}</TableCell>
                  <TableCell component="th">IG</TableCell>
                  <TableCell component="th">Peso</TableCell>
                  <TableCell component="th">Edema</TableCell>
                  <TableCell component="th">PA</TableCell>
                  <TableCell component="th" className={styles.altUte}>
                    Alt. Ute.
                  </TableCell>
                  <TableCell component="th">BCF</TableCell>
                  <TableCell component="th" className={styles.apFetal}>
                    Ap. fetal
                  </TableCell>
                  <TableCell component="th">Toque</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">
                    {getValueDate("date", consulta, "dd/mm/aaaa")}
                  </TableCell>
                  <TableCell>
                    {getValue("idadeGestacional", consulta)}
                  </TableCell>
                  <TableCell>{getValue("peso", consulta)}</TableCell>
                  <TableCell>{getValue("edema", consulta)}</TableCell>
                  <TableCell>{getValue("pressaoArterial", consulta)}</TableCell>
                  <TableCell>{getValue("alturaUterina", consulta)}</TableCell>
                  <TableCell>{getValue("bcf", consulta)}</TableCell>
                  <TableCell>
                    {getValue("apresentacaoFetal", consulta)}
                  </TableCell>
                  <TableCell>{getValue("dinamicaUterina", consulta)}</TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    </Panel>
  );
};

export default Consultas;
