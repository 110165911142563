import React from "react";

// MUI
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

// Moment
import moment from "moment";

// Components
import Panel from "../Panel/Panel";

// Types
import { ExameDeImagem } from "@core/@http/carteirinha/carteirinhaType";

// Styles
import styles from "./ExamesImagem.module.scss";

interface iProps {
  data?: ExameDeImagem[];
}

const exames = Array.from({ length: 7 });

const tiposExames = [
  { value: 1, description: "Morfológico do 1º tri" },
  { value: 2, description: "Morfológico do 2º tri" },
  { value: 3, description: "USG Obstétrico" },
  { value: 4, description: "Cervicometria" },
  { value: 5, description: "Doppler Obstétrico" },
  { value: 6, description: "USG Transvaginal" },
  { value: 7, description: "Ecocardiograma fetal" },
  { value: 8, description: "Outro" },
];

const ExamesImagem: React.FC<iProps> = ({ data }) => {
  const getExame = (index: number) => data?.[index];

  const getValue = (
    prop: string,
    exame?: ExameDeImagem,
    valueDefault: string = "-",
    truncate?: number
  ) => {
    if (!exame) {
      return valueDefault;
    }

    const value = exame[prop];

    if (value) {
      return String(value).substring(0, truncate);
    }

    return valueDefault;
  };

  const getValueDate = (
    prop: string,
    exame?: ExameDeImagem,
    valueDefault: string = "dd/mm/aaaa"
  ) => {
    const date = getValue(prop, exame, valueDefault);

    if (date && date != valueDefault) {
      return moment(date).format("DD/MM/YYYY");
    }

    return valueDefault;
  };

  const getNameExame = (tipoExame?: number, valueDefault: string = "-") => {
    if (!tipoExame) {
      return valueDefault;
    }

    return (
      tiposExames.find((tipo) => tipo.value == tipoExame)?.description ||
      valueDefault
    );
  };

  return (
    <Panel title="Exames de Imagem">
      <TableContainer className={styles.table}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" className={styles.exame}>
                Exame
              </TableCell>
              <TableCell component="th" className={styles.data}>
                Rotina 1
              </TableCell>
              <TableCell component="th">Result.</TableCell>
            </TableRow>
            {exames.map((_, index) => {
              const exame = getExame(index);

              if (!exame) {
                return null;
              }

              return (
                <TableRow key={index}>
                  <TableCell>
                    {getNameExame(exame?.examesDeImagemTipo)}
                  </TableCell>
                  <TableCell>{getValueDate("date", exame)}</TableCell>
                  <TableCell>{getValue("resultado", exame, "-", 45)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Panel>
  );
};

export default ExamesImagem;
