import React from 'react';

// Components
import Panel from '../Panel/Panel';

// Types
import { CarteirinhaImprimir } from "@core/@http/carteirinha/carteirinhaType";

// Styles
import styles from './Historico.module.scss'

interface iProps {
  data?: CarteirinhaImprimir
}

const rows = Array.from({ length: 3 }).map(() => ({
  columns: Array.from({ length: 2 })
}))

const Historico: React.FC<iProps> = ({ data }) => {
  const historicoFamiliar = data?.historicoFamiliar || [];
  const historicoObstetrico = data?.historicoObstetrico || [];
  const historicoPessoal = data?.historicoPessoal || [];

  const formatStringValue = (value: string) => {
    let values = value.replace(" de", "")
      .replace("primeiro", "1°")
      .replace("segundo", "2°")
      .replace("teceiro", "3°")
      .replace("Hipo ", "")
      .trim().split(" ");

    if (values.length == 1) {
      return values[0];
    }

    values[0] = values[0].substring(0, 4) + ".";

    return values.join(" ");
  }

  const renderTags = (historico: string[]) => {
    let index = -1;

    return rows.map(({ columns }, iRow) => (
      <div key={iRow} className={styles.row}>
        {columns.map((_, iColumn) => {
          index++;

          const historicoData = historico[index];

          if (!historicoData) {
            return null;
          }

          return (
            <div key={iColumn} className={styles.tag}>
              {formatStringValue(historicoData)}
            </div>
          )
        })}
      </div>
    ))
  }

  return (
    <Panel title='Historico'>
      <div className={styles.container}>
        <div className={styles.historico}>
          <span>Familiar</span>
          <div className={styles.tags}>
            {renderTags(historicoFamiliar)}
          </div>
        </div>
        <div className={styles.historico}>
          <span>Obstétrico</span>
          <div className={styles.tags}>
            {renderTags(historicoObstetrico)}
          </div>
        </div>
        <div className={styles.historico}>
          <span>Pessoal</span>
          <div className={styles.tags}>
            {renderTags(historicoPessoal)}
          </div>
        </div>
      </div>
    </Panel>
  )
}

export default Historico;