import React, { useState, useEffect } from 'react';

// MUI
import { Fab, CircularProgress, Backdrop } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

// Endpoints
import carteirinhaProblems from "@core/@http/carteirinha/carteirinhaProblems";

// Types
import { CarteirinhaImprimir } from "@core/@http/carteirinha/carteirinhaType";

// Components
import Carteirinha from './components/Carteirinha/Carteirinha';
import Historico from './components/Historico/Historico';
import Consultas from './components/Consultas/Consultas';
import Destaques from './components/Destaques/Destaques';
import ExamesLaboratoriais from './components/ExamesLaboratoriais/ExamesLaboratoriais';
import ExamesImagem from './components/ExamesImagem/ExamesImagem';
import Vacinas from './components/Vacinas/Vacinas';
import Footer from './components/Footer/Footer';

// Hooks
import { useToast } from "providers/ToastProvider/ToastProvider";
import { useParams } from "react-router-dom";

// Styles
import styles from './PrintService.module.scss';

const PrintService = () => {
  const { id } = useParams();

  const [data, setData] = useState<CarteirinhaImprimir>();
  const [loading, setLoading] = useState(true);

  const { toast } = useToast();

  const handlePrint = () => {
    window.print();
  }

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await carteirinhaProblems.getPrintCarteirinha(id ?? "");

      setData(response);
    } catch (error: any) {
      toast.error({
        title: "Erro",
        message: "Erro ao buscar carteirinha",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <>
      <div className={styles.btnPrint}>
        <Fab onClick={handlePrint}>
          <PrintIcon />
        </Fab>
      </div>
      <div className={styles.container}>
        <div className={styles.page}>
          <div className={styles.dividerVertical}>
            <div className={styles.carteirinha}>
              <Carteirinha data={data}/>
            </div>
            <div className={styles.historico}>
              <Historico data={data}/>
            </div>
            <div className={styles.consultas}>
              <Consultas data={data?.consultas}/>
            </div>
          </div>
          <div className={styles.dividerVertical}>
            <div className={styles.destaques}>
              <Destaques data={data}/>
            </div>
            <div className={styles.examesLaboratoriais}>
              <ExamesLaboratoriais data={data?.examesLaboratoriais}/>
            </div>
            <div className={styles.examesImagem}>
              <ExamesImagem data={data?.examesImagem}/>
            </div>
            <div className={styles.vacinas}>
              <Vacinas data={data?.vacinas}/>
            </div>
            <div className={styles.qrcode}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintService;