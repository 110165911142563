import React, { useState, useEffect } from "react";

// MUI
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

// Types
import { ExameLaboratorial } from "@core/@http/exameLaboratorias/exameLaboratorias";

// Components
import Input from "components/input/Input";

// Styles
import styles from "./OutrosForm.module.scss";

interface iProps {
  data?: ExameLaboratorial[];
  rotina: number;
  gestationId: string;
  carteirinhaId: string;
  onChange?: (exame: ExameLaboratorial) => void;
}

const exameLaboratorialTipoOutros = 19;

const exames = Array.from({ length: 10 });

const RotinaForm: React.FC<iProps> = ({
  data,
  rotina,
  gestationId,
  carteirinhaId,
  onChange,
}) => {
  const [dataRotina, setDataRotina] = useState<ExameLaboratorial[]>();

  const filterDataRotina = async () => {
    const filterRotina = data?.filter((exame) => exame.rotina == rotina);

    setDataRotina(filterRotina);
  };

  const createExame = (index: number): ExameLaboratorial => ({
    index,
    rotina,
    gestationId,
    carteirinhaId,
    examesLaboratoriaisTipo: exameLaboratorialTipoOutros,
  });

  const getExame = (index: number) =>
    dataRotina?.find((exame) => exame.index == index);

  const handleOnChange = (
    prop: string,
    value: string | number | boolean,
    index: number
  ) => {
    const exame = getExame(index) || createExame(index);
    const exameData = { ...exame, [prop]: value };

    onChange?.(exameData);
  };

  useEffect(() => {
    if (data) {
      filterDataRotina();
    }
  }, [data]);

  return (
    <>
      <Grid container xs={12} sm={12} md={12} xl={8}>
        <TableContainer className={styles.containerExames}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={styles.head} width="150px">
                  Nome
                </TableCell>
                <TableCell className={styles.head} width="150px">
                  Data
                </TableCell>
                <TableCell className={styles.head}>Resultados</TableCell>
                <TableCell className={styles.head} align="center">
                  Destacar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exames.map((_, index) => {
                const exame = getExame(index);

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Input
                        type="text"
                        id={`nome-exame-${index}`}
                        style={{ margin: 0 }}
                        value={exame?.nome}
                        onChange={(value) =>
                          handleOnChange("nome", value, index)
                        }
                      />
                    </TableCell>
                    <TableCell className={styles.text}>
                      <Input
                        type="date"
                        id={`data-exame-${index}`}
                        style={{ margin: 0 }}
                        value={exame?.date}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        type="text"
                        id={`resultado-exame-${index}`}
                        style={{ margin: 0 }}
                        value={exame?.resultado}
                        onChange={(value) =>
                          handleOnChange("resultado", value, index)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      {exame?.destaque ? (
                        <StarIcon
                          className={styles.starIcon}
                          onClick={() =>
                            handleOnChange("destaque", false, index)
                          }
                        />
                      ) : (
                        <StarBorderIcon
                          className={styles.starIcon}
                          onClick={() =>
                            handleOnChange("destaque", true, index)
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default RotinaForm;
