// Modules
import { LoginScreen } from "../../modules/screens/login";

// Styles
import "../global";

const LoginPage = () => {
  return <LoginScreen />;
};

export default LoginPage;
