import React from 'react';

// Styles
import styles from './Panel.module.scss';

interface iProps {
  title: string
  children?: any
}

const Panel: React.FC<iProps> = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title}
      </div>
      {children}
    </div>
  )
} 

export default Panel;