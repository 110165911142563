import React, { useState, useEffect } from "react";

// MUI
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

// Types
import { ExameLaboratorial } from "@core/@http/exameLaboratorias/exameLaboratorias";

// Components
import Input from "components/input/Input";

// Styles
import styles from "./RotinaForm.module.scss";

interface iProps {
  data?: ExameLaboratorial[];
  rotina: number;
  gestationId: string;
  carteirinhaId: string;
  onChange?: (exame: ExameLaboratorial | ExameLaboratorial[]) => void;
}

const tiposExames = [
  { value: 1, name: "Hemograma" },
  { value: 2, name: "Tipagem/RH" },
  { value: 3, name: "Coombs indireto" },
  { value: 4, name: "Glicemia" },
  { value: 5, name: "TOTG" },
  { value: 6, name: "TSH/T4L" },
  { value: 7, name: "Súmario de Urina" },
  { value: 8, name: "Urocultura" },
  { value: 9, name: "Sifilis" },
  { value: 10, name: "Toxoplasmose" },
  { value: 11, name: "HIV" },
  { value: 12, name: "Hepatite B" },
  { value: 13, name: "Hepatite C" },
  { value: 14, name: "Cit. Oncológica" },
  { value: 15, name: "Strepto" },
  { value: 16, name: "Outros" },
];

const RotinaForm: React.FC<iProps> = ({
  data,
  rotina,
  gestationId,
  carteirinhaId,
  onChange,
}) => {
  const [dataRotina, setDataRotina] = useState<ExameLaboratorial[]>();
  const [dataExame, setDataExame] = useState<string>();

  const filterDataRotina = async () => {
    const filterRotina = data?.filter((exame) => exame.rotina == rotina);

    setDataRotina(filterRotina);
  };

  const createExame = (tipoExame: number): ExameLaboratorial => ({
    rotina,
    gestationId,
    carteirinhaId,
    date: dataExame,
    examesLaboratoriaisTipo: tipoExame,
  });

  const getExame = (tipoExame: number) =>
    dataRotina?.find((exame) => exame.examesLaboratoriaisTipo == tipoExame);

  const handleOnChange = (
    prop: string,
    value: string | number | boolean,
    tipoExame: number
  ) => {
    const exame = getExame(tipoExame) || createExame(tipoExame);
    const exameData = { ...exame, [prop]: value };

    onChange?.(exameData);
  };

  const handleOnChangeData = (value: string) => {
    const examesData = dataRotina?.map((exame) => ({ ...exame, date: value }));

    if (examesData?.length) {
      onChange?.(examesData);
    } else {
      setDataExame(value);
    }
  };

  useEffect(() => {
    if (data) {
      filterDataRotina();
    }
  }, [data]);

  useEffect(() => {
    if (dataRotina?.length) {
      setDataExame(dataRotina[0].date);
    }
  }, [dataRotina]);

  return (
    <>
      <Input
        type="date"
        id="data-dum"
        label="Data"
        width="30%"
        value={dataExame}
        onChange={handleOnChangeData}
      />
      <Grid container xs={12} sm={12} md={12} xl={8}>
        <TableContainer className={styles.containerExames}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={styles.head} width="150px">
                  Exames
                </TableCell>
                <TableCell className={styles.head} align="left">
                  Resultados
                </TableCell>
                <TableCell className={styles.head} align="center">
                  Destacar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tiposExames.map((tipoExame) => {
                const exame = getExame(tipoExame.value);

                return (
                  <TableRow key={tipoExame.value}>
                    <TableCell className={styles.text}>
                      {tipoExame.name}
                    </TableCell>
                    <TableCell>
                      <Input
                        type="text"
                        id={`resultado-exame-${tipoExame.value}`}
                        style={{ margin: 0 }}
                        value={exame?.resultado}
                        onChange={(value) =>
                          handleOnChange("resultado", value, tipoExame.value)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      {exame?.destaque ? (
                        <StarIcon
                          className={styles.starIcon}
                          onClick={() =>
                            handleOnChange("destaque", false, tipoExame.value)
                          }
                        />
                      ) : (
                        <StarBorderIcon
                          className={styles.starIcon}
                          onClick={() =>
                            handleOnChange("destaque", true, tipoExame.value)
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default RotinaForm;
