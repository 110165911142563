import React from "react";

// MUI
import { Box } from '@mui/material';

// Components
import Button from "components/button/Button";

// Styles
import styles from "./Tab.module.scss";

interface iProps {
  index: number;
  value: number;
  label: string;
  onChange: (index: number) => void;
}

const Tab: React.FC<iProps> = ({ index, value, label, onChange }) => {

  const active = index == value ? "primary" : "secondary";

  const handleOnChange = () => onChange(index);

  return (
    <Button
      name={label}
      onClick={handleOnChange}
      type={active}
      className={styles.btnTab}
    />
  );
};

export default Tab;