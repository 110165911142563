import React from 'react';

// MUI
import {TableContainer, Table, TableBody, TableRow, TableCell} from '@mui/material';

import moment from 'moment';

// Components
import Panel from '../Panel/Panel';

// Types
import { ExameLaboratorial } from '@core/@http/carteirinha/carteirinhaType';

// Styles
import styles from './ExamesLaboratoriais.module.scss';

interface iProps {
  data?: ExameLaboratorial[]
}

const tiposExames = [
  {value: 1, name: "Hemograma"},
  {value: 2, name: "Tipagem/RH"},
  {value: 3, name: "Coombs"},
  {value: 4, name: "Glicemia"},
  {value: 5, name: "TOTG"},
  {value: 6, name: "TSH/T4L"},
  {value: 7, name: "Súm. Urina"},
  {value: 8, name: "Urocultura"},
  {value: 9, name: "Sifilis"},
  {value: 10, name: "Toxoplasm."},
  {value: 11, name: "HIV"},
  {value: 12, name: "Hepatite B"},
  {value: 13, name: "Hepatite C"},
  {value: 14, name: "Cit. Onco."},
  {value: 15, name: "Strepto"},
  {value: 16, name: "Outros"},
]

const ExamesLaboratoriais: React.FC<iProps> = ({ data }) => {
  const getExame = (rotina: number, tipoExame: number) => data?.find((exame) => exame.rotina == rotina && exame.examesLaboratoriaisTipo == tipoExame);

  const getValue = (prop: string, exame?: ExameLaboratorial, valueDefault: string = "-", truncate?: number) => {
    if (!exame) {
      return valueDefault;
    }

    const value = exame[prop];

    if (value) {
      return String(value).substring(0, truncate);
    }

    return valueDefault;
  }

  const getValueDate = (prop: string, exame?: ExameLaboratorial, valueDefault: string = "dd/mm/aaaa") => {
    const date = getValue(prop, exame, valueDefault);

    if (date && date != valueDefault) {
      return moment(date).format("DD/MM/YYYY");
    }

    return valueDefault;
  }

  return (
    <Panel title='Exames Laboratoriais'>
      <TableContainer className={styles.table}>
        <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th">Exame</TableCell>
                <TableCell component="th">Rotina 1</TableCell>
                <TableCell component="th">Result.</TableCell>
                <TableCell component="th">Rotina 2</TableCell>
                <TableCell component="th">Result.</TableCell>
                <TableCell component="th">Rotina 3</TableCell>
                <TableCell component="th">Result.</TableCell>
              </TableRow>
              {tiposExames.map((tipoExame) => {
                const exameRotina1 = getExame(1, tipoExame.value);
                const exameRotina2 = getExame(2, tipoExame.value);
                const exameRotina3 = getExame(3, tipoExame.value);

                if (!exameRotina1 && !exameRotina2 && !exameRotina3) {
                  return null;
                }

                return (
                  <TableRow key={tipoExame.value}>
                    <TableCell>
                      {tipoExame.name}
                    </TableCell>
                    <TableCell>{getValueDate("date", exameRotina1)}</TableCell>
                    <TableCell>{getValue("resultado", exameRotina1, "-", 10)}</TableCell>
                    <TableCell>{getValueDate("date", exameRotina2)}</TableCell>
                    <TableCell>{getValue("resultado", exameRotina2, "-", 10)}</TableCell>
                    <TableCell>{getValueDate("date", exameRotina3)}</TableCell>
                    <TableCell>{getValue("resultado", exameRotina3, "-", 10)}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
      </TableContainer>
    </Panel>
  )
}

export default ExamesLaboratoriais;