import React from 'react';

// MUI
import {TableContainer, Table, TableBody, TableRow, TableCell} from '@mui/material';

// Moment
import moment from 'moment';

// Components
import Panel from '../Panel/Panel';

// Types
import { Vacina } from '@core/@http/carteirinha/carteirinhaType';

// Styles
import styles from './Vacinas.module.scss';

interface iProps {
  data?: Vacina[]
}

const tiposVacinas = [
  {value: 2, name: "Covid"},
  {value: 3, name: "Hepatite B"},
  {value: 1, name: "dtPa"},
  {value: 4, name: "Gripe"},
];

const Vacinas: React.FC<iProps> = ({ data }) => {
  const getVacina = (tipoVacina: number) => data?.find((vacina) => vacina.vacinaTipos == tipoVacina);

  const getValueDate = (date?: string, valueDefault: string = "dd/mm/aaaa") => {
    if (date && date != valueDefault) {
      return moment(date).format("DD/MM/YYYY");
    }

    return valueDefault;
  }

  return (
    <Panel title='Vacinas'>
      <TableContainer className={styles.table}>
        <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th" className={styles.exame}>Exame</TableCell>
                <TableCell component="th" className={styles.data}>Data</TableCell>
                <TableCell component="th">Imunização</TableCell>
              </TableRow>
              {tiposVacinas.map((tipoVacina) => {
                const vacina = getVacina(tipoVacina.value);

                if (!vacina) {
                  return null;
                }

                return (
                  <TableRow key={tipoVacina.value}>
                    <TableCell>
                      {tipoVacina.name}
                    </TableCell>
                    <TableCell>
                      {getValueDate(vacina?.date)}
                    </TableCell>
                    <TableCell>
                      {vacina ? "OK" : "-"}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
      </TableContainer>
    </Panel>
  )
}

export default Vacinas;