import { getAxios } from "@core/@http/axiosConfig/axiosConfig";
import moment from "moment";

export type createExameLaboratorialProps = {
  gestationId: string;
  carteirinhaId: string;
  date: string;
  resultado: string;
  examesLaboratoriaisTipo: number;
  destaque: boolean;
};

export interface ExameLaboratorial {
  id?: string;
  gestationId?: string;
  carteirinhaId?: string;
  examesLaboratoriaisTipo: number;
  rotina: number;
  date?: string;
  resultado?: string;
  destaque?: boolean;
  nome?: string;
  index?: number;
}

const sortByNameDesc = (a: ExameLaboratorial, b: ExameLaboratorial) => {
  if ((a.nome as string) > (b.nome as string)) {
    return 1;
  }
  if (a.nome == b.nome) {
    return 0;
  }

  return -1;
}

async function getExameLaboratoriais(carteirinhaId: string) {
  const response = await getAxios().get<ExameLaboratorial[]>(
    `${process.env.REACT_APP_API_URL}/Carteirinha/${carteirinhaId}/exames-laboratoriaisv2`
  );
  
  return response.data.sort(sortByNameDesc);
}
async function deleteExameLaboratoriais(id: string) {
  const response = await getAxios().delete(
    `${process.env.REACT_APP_API_URL}/Carteirinha/exame-laboratoriais/${id}`
  );
  return response;
}

async function createExameLaboratoria(
  data: ExameLaboratorial[]
): Promise<any> {
  const dataFormat = data.map((exame) => ({
    ...exame,
    date: moment(exame.date).toISOString(),
    index: undefined,
  }))

  const response = await getAxios().post(
    `${process.env.REACT_APP_API_URL}/Carteirinha/exames-laboratoriaisV2/`,
    dataFormat
  );

  return response.data;
}

export default {
  getExameLaboratoriais,
  createExameLaboratoria,
  deleteExameLaboratoriais,
};
